import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`According to the most recent `}<a parentName="p" {...{
        "href": "http://frac.org/pdf/food_hardship_2014.pdf"
      }}>{`data`}</a>{` reported by the `}<a parentName="p" {...{
        "href": "http://frac.org"
      }}>{`Food Research and Action Center (FRAC)`}</a>{`, more than 47 million Americans receive Supplementary Nutrition Assistance (SNAP - the program formerly known as food stamps) to help remedy food scarcity issues in the home. The amount of the benefit varies depending on the number of folks in the household but we’re talking roughly $150/person/month or ~$5 per day. While this stipend is meant to be "supplementary", the reality is that, for many Americans, this is their whole food budget. Which means that learning how to stretch a food budget to maximize the nutritional value for each dollar is of critical importance.`}</p>
    <p>{`I learned many of these facts from Duke Storen who heads up Research, Advocacy, and Partnership Development for `}<a parentName="p" {...{
        "href": "http://www.nokidhungry.org/"
      }}>{`Share Our Strength’s No Kid Hungry`}</a>{` campaign and is one of the leading advocates for solving the food scarcity problem in this country. Duke got to Share our Strength by way of the Food and Nutrition Service and the Department of Agriculture so he’s seen these issues through a number of lenses. `}<em parentName="p">{`(A shout out to Josh Wachs and Michael Andrews for putting the pieces together, over a dog walking session, that they should connect us.)`}</em></p>
    <p>{`During my first meeting with Duke, I gave him a demo on how banks and governments were using PayPerks to drive financial capabilities and how they were using their prepaid card programs as a means to market PayPerks, track cardholder behavior change and ultimately do prize fulfillment. It took him all of 30 seconds to see the implications in the nutrition capability sphere. We spent the next 45 minutes fantasizing about the impact a customized version of PayPerks could have with regards to nutrition capabilities, particularly if we were able to connect it to the cards used to disburse SNAP funds. We envisioned a day when all 47M SNAP recipients would have access to a nutrition-based education through PayPerks and they could earn points for making food purchasing choices that maximize the nutritional value for their dollars with prizes would be loaded back onto their SNAP cards. We left the meeting with a high five and spent the next two-ish years getting the right folks on board to get this off the ground.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      